import {bindable, BindingEngine, customElement, inject, LogManager, observable} from 'aurelia-framework';
import {Client} from '../../api/client';
import * as _ from "lodash";
import '../../array/array-move';

import "./cart.less";

const logger = LogManager.getLogger("OrderTourismCart");

@customElement('menu-toolbar')
@inject(
    Client
)
export class OrderTourismCart {

    client;

    @bindable order;

    loading;

    constructor(client) {
        this.client = client;
    }

    initializeToolbars(toolbars) {
        const menu = toolbars;
        let newMenu = [];

        for (const toolbar of menu) {

            if (toolbar.label == 'order.actions' || toolbar.label == 'EK' || toolbar.label == 'RV') {
                continue;
            }

            for (const action of toolbar.actions) {
                action.buttonClass = action.buttonClass || 'btn btn-default btn-sm';
                action.showLabel = !action.icon;

                if (action.hasOwnProperty('workflowId')) {
                    action.type = 'workflow';
                    action.workflowId = _.castArray(action.workflowId)
                        .map(workflowId => (-1 === workflowId.indexOf('/') ? 'tourism/' : '') + workflowId);
                    if (1 === action.workflowId.length) {
                        action.workflowId = action.workflowId[0];
                    }
                }
            }
            newMenu.push(toolbar);
        }

        this.menu = newMenu;

        logger.debug('Cart view toolbars were initialized', menu);
    }

    async orderChanged() {

        // avoid parallel executions
        if (this.loading) {
            return;
        }

        this.loading = true;

        this.client.get('order/cart-view/configuration/' + this.order.id).then(cartViewConfig => {
            this.initializeToolbars(cartViewConfig.toolbars);
        });

        this.loading = false;
    }
}
